import React from 'react';

import css from './LocationAutocompleteInput.module.css';

const IconHourGlass = () => (

  <svg className={css.iconSvg} width="30px" height="30px" viewBox="0 0 0.9 0.9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.45 0.075c-0.165 0 -0.3 0.135 -0.3 0.3 0 0.203 0.263 0.431 0.274 0.443 0.007 0.004 0.019 0.007 0.026 0.007 0.007 0 0.019 -0.004 0.026 -0.007 0.011 -0.011 0.274 -0.24 0.274 -0.443 0 -0.165 -0.135 -0.3 -0.3 -0.3zm0 0.664c-0.079 -0.075 -0.225 -0.236 -0.225 -0.364 0 -0.124 0.101 -0.225 0.225 -0.225s0.225 0.101 0.225 0.225 -0.146 0.289 -0.225 0.364zM0.45 0.225c-0.083 0 -0.15 0.068 -0.15 0.15s0.068 0.15 0.15 0.15 0.15 -0.068 0.15 -0.15 -0.068 -0.15 -0.15 -0.15zm0 0.225c-0.041 0 -0.075 -0.034 -0.075 -0.075s0.034 -0.075 0.075 -0.075 0.075 0.034 0.075 0.075 -0.034 0.075 -0.075 0.075z" fill="#0D0D0D" /></svg>
);

export default IconHourGlass;
